import { useCallback, useEffect, useState } from 'react';
import { paramCase, sentenceCase } from 'change-case';
import { useParams, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { CsvBuilder } from 'filefy';
// @mui
import {
  Container,
  Box,
  Card,
  Stack,
  Table,
  Avatar,
  Button,
  Divider,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  TableHead,
  CardHeader,
  Typography,
  TableContainer,
} from '@mui/material';// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getProducts } from '../../redux/slices/product';
import { _video } from '../../_mock';

// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import VideoViewForm from '../../sections/@dashboard/video/VideoViewForm';
import axiosInstance from '../../utils/axios';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

export default function VideoView() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isMountedRef = useIsMountedRef();
  const { id = '' } = useParams();
  const [ video, setVideo ] = useState({});
  const [tableData, setTableData] = useState([]);
  const isLike = pathname.includes('like');

  const getVideo = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/video/${id}/get`);
  
         setVideo(response.data.data);
        
  
    } catch (err) {
       console.log(err);
    }
  }, [isMountedRef]);

  const getData = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/video/${id}/${isLike ? 'likes' : 'views'}`);
  
         setTableData(response.data.data);
        
  
    } catch (err) {
       console.log(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getVideo();
    getData();
  },[getVideo, getData]);

  const exportCSV = useCallback(() => {
    const builder = new CsvBuilder('video-stats-export.csv');
    const data = tableData.map(row => [`${row.user.first_name} ${row.user.last_name}`, row.user.email, row.user.type, "", row.user.last_accessed_at, row.user.createdAt ,row.createdAt]);
    builder
    .setDelimeter(',')
    .setColumns(['Name', 'Email', 'Role', 'Phone', 'Last Opened', 'Registered', 'Date & Time' ])
    .addRows(data)
    .exportFile();
  });

  return (
    <Page title="Video: Stats">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={isLike ? 'Video Likes' : 'Video Views'}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Video',
              href: PATH_DASHBOARD.video.root,
            },
            { name: isLike ? 'Video Likes' : 'Video Views'},
          ]}
          action={
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:download-fill" />}
              onClick={exportCSV}
            >
              Export CSV
            </Button>
          }
        />

      <Card>
        <CardHeader title={`Video Stats: ${video?.name}` }sx={{ mb: 3 }} />
       
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ minWidth: 200 }}>Name</TableCell>
                  <TableCell sx={{ minWidth: 160 }}>Email</TableCell>
                  <TableCell sx={{ minWidth: 160 }}>Role</TableCell>
                  <TableCell sx={{ minWidth: 120 }}>Phone</TableCell>
                  <TableCell sx={{ minWidth: 120 }}>Last Opened</TableCell>
                  <TableCell sx={{ minWidth: 120 }}>Registered</TableCell>
                  <TableCell sx={{ minWidth: 120 }}>Date &amp; Time</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                     
                        
                        <Typography variant="subtitle2">{row.user.first_name} {row.user.last_name}</Typography>
                      
                    </TableCell>

                    <TableCell>{row.user.email}</TableCell>
                    
              

                    <TableCell>
                      <Label
                        variant={'ghost'}
                        color={
                          'success'
                        }
                      >
                        {sentenceCase(row.user.type)}
                      </Label>
                    </TableCell>

                    <TableCell>{""}</TableCell>
                    <TableCell sx={{ textTransform: 'capitalize' }}>{format(new Date(row.user.last_accessed_at), 'hh:mm a dd/MMM/yyyy')}</TableCell>
                    <TableCell sx={{ textTransform: 'capitalize' }}>{format(new Date(row.user.createdAt), 'hh:mm a dd/MMM/yyyy')}</TableCell>
                    <TableCell>{format(new Date(row.createdAt), 'hh:mm a dd/MMM/yyyy')}</TableCell>
                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
      

        <Divider />

        {/* <Box sx={{ p: 2, textAlign: 'right' }}>
          <Button size="small" color="inherit" endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}>
            Export
          </Button>
        </Box> */}
      </Card>
      </Container>
    </Page>
  );
}
