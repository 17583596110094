/* eslint-disable camelcase */
import { paramCase } from 'change-case';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useState, useMemo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import { Avatar, Checkbox, TableRow, TableCell, Typography, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Alert, Box } from '@mui/material';
// components
import { useNavigate } from 'react-router';
import { format } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import axiosInstance from '../../../../utils/axios';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import { PATH_DASHBOARD } from '../../../../routes/paths';




// import Button from 'src/theme/overrides/Button';


// ----------------------------------------------------------------------

SubscriptionTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  // onStatusRow: PropTypes.func,
};

export default function SubscriptionTableRow({ row, selected, onViewRow, onEditRow, onSelectRow, onDeleteRow, onStatusRow, onActiveRow, onRecommendedRow, onOrderUpdate
}) {
  const theme = useTheme();

  const { _id, title, type, validity, price, offer_price, trial, status, businessCollaboration, active, order, recommended, createdAt } = row;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () => ({
      order: order === undefined ? 0 : order,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const NewSubscriptionSchema = Yup.object().shape({
    order: Yup.number().moreThan(0, 'Order should be more than 0'),
  });

  const methods = useForm({
    resolver: yupResolver(NewSubscriptionSchema),
    defaultValues,
  });

  const {
    // reset,
    watch,
    // control,
    setError,
    // setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const onOrderClick = () => {
    console.log('Click ---->');
  }

  const [open, setOpen] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOrderUpdate = async () => {
    try {
      console.log();
    } catch (error) {
      console.error(error);
      // enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const onSubmit = async () => {
    try {

      await axiosInstance.post(`/subscription-plan/${_id}/update-order`, getValues());
      enqueueSnackbar("Order Updated!", { variant: 'success' });
      handleCloseDialog();
      onOrderUpdate();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to update", { variant: 'error' });
      // if (isMountedRef.current) {
      //   setError('afterSubmit', error);
      // }
    }
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur()
    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Update Order"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Enter the order value to update.
          </DialogContentText>
        </DialogContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          {!!errors.afterSubmit && <Alert sx={{ mb: 2 }} severity="error">{errors.afterSubmit.message}</Alert>}
          <Box sx={{
            marginLeft: "15px",
            marginRight: "15px",
          }}>
            <RHFTextField name="order" label="Order" type="number" onWheel={numberInputOnWheelPreventChange} />
            <Typography variant="caption" color="primary" sx={{
              fontStyle: "italic"
            }}>
              * Order in which subscription will be shown in the frontend. Least value will be shown first
            </Typography>
          </Box>


        </FormProvider>
        <DialogActions>
          <Button color='error' onClick={handleCloseDialog}>Cancel</Button>
          <Button 
          type="submit"
          onClick={onSubmit} autoFocus>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <TableRow hover selected={selected}>
        {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}


        <TableCell align="left">
          {title}
        </TableCell>

        <TableCell align="left">
          {type === 'business_sub' ? 'Business Subscription' : 'Electrician Subscription'}
        </TableCell>

        <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
          {validity.replace('_', ' ')}
        </TableCell>

        <TableCell align="right">
          ${price}
        </TableCell>


        <TableCell align="right">
          ${offer_price}
        </TableCell>


        <TableCell align="left">
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={(!trial && 'error') || 'success'}
            sx={{ textTransform: 'capitalize' }}
          >
            {trial ? 'Active' : 'Inactive'}
          </Label>
        </TableCell>


        <TableCell align="left">
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={((status === 'draft') && 'warning') || 'success'}
            sx={{ textTransform: 'capitalize' }}
          >
            {status}
          </Label>
        </TableCell>


        <TableCell align="left">
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={(!businessCollaboration && 'warning') || 'success'}
            sx={{ textTransform: 'capitalize' }}
          >
            {businessCollaboration ? 'true' : 'false'}
          </Label>
        </TableCell>


        <TableCell align="center">
          <div>
          {order === undefined ? '--' : order}{' '}<Iconify onClick={() => setOpenDialog(true)} sx={{ color: 'success.main', fontSize: "15px", marginLeft:'5px' }} icon={'material-symbols:edit-square-outline-rounded'} />
          </div>
        </TableCell>


        <TableCell align="left">
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={(!active && 'error') || 'success'}
            sx={{ textTransform: 'capitalize' }}
          >
            {active ? 'Active' : 'Inactive'}
          </Label>
        </TableCell>
        <TableCell>
          {createdAt && format(new Date(createdAt), 'MMM dd, yyyy')}
        </TableCell>

        <TableCell align="right">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              <>
                <MenuItem
                  onClick={() => {
                    if (status === 'draft') {
                      onEditRow();
                    } else {
                      onViewRow();
                    }
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={'ic:outline-remove-red-eye'} />
                  {status === 'draft' ? 'Edit' : 'View'}
                </MenuItem>

                {active && (<MenuItem
                  onClick={() => {
                    onActiveRow(!active);
                    handleCloseMenu();
                  }}
                  sx={{ color: 'error.main' }}
                >
                  <Iconify icon={'eva:lock-outline'} />
                  Deactivate
                </MenuItem>)}
                {!active && (<MenuItem
                  onClick={() => {
                    onActiveRow(!active);
                    handleCloseMenu();
                  }}
                  sx={{ color: 'success.main' }}
                >
                  <Iconify icon={'eva:lock-outline'} />
                  Activate
                </MenuItem>)}


                {(recommended === true) && (<MenuItem
                  onClick={() => {
                    onRecommendedRow(!(recommended === true));
                    handleCloseMenu();
                  }}
                  sx={{ color: 'error.main' }}
                >
                  <Iconify icon={'mingcute:certificate-line'} />
                  Un-Recommend
                </MenuItem>)}
                {!(recommended === true) && (<MenuItem
                  onClick={() => {
                    onRecommendedRow(!(recommended === true));
                    handleCloseMenu();
                  }}
                  sx={{ color: 'success.main' }}
                >
                  <Iconify icon={'mingcute:certificate-line'} />
                  Recommended
                </MenuItem>)}


                {(status === 'draft') && (<MenuItem
                  onClick={() => {
                    onStatusRow();
                    handleCloseMenu();
                  }}
                  sx={{ color: 'success.main' }}
                >
                  <Iconify icon={'eva:lock-outline'} />
                  Publish
                </MenuItem>)}

                {
                  (status === 'draft') && (<MenuItem
                    onClick={() => {
                      onDeleteRow();
                      handleCloseMenu();
                    }}
                    sx={{ color: 'error.main' }}
                  >
                    <Iconify icon={'eva:trash-2-outline'} />
                    Delete
                  </MenuItem>)
                }
              </>
            }
          />
        </TableCell>
      </TableRow>

    </>
  );
}
