import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
// @mui
import { Alert, Card, Container, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// routes
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// components
import { FormProvider, RHFEditor, RHFTextField } from '../../components/hook-form';
// components
import Page from '../../components/Page';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import useSettings from '../../hooks/useSettings';
import { PATH_DASHBOARD } from '../../routes/paths';
import axiosInstance from '../../utils/axios';



const DataSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email(),
  phone: Yup.string().required('Phone number is required'),
  address: Yup.string().required('Address is required'),
});


const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

export default function ContactUs() {
  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const [data, setData] = useState('');

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');

  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () => ({
      email,
      phone,
      address,
    }),
    [email,phone,address]
  );

  const methods = useForm({
    resolver: yupResolver(DataSchema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    getValues,
    setError,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();

  const getData = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/information/contact_us');

      setValue('email', response.data.data.data.email);
      setValue('phone', response.data.data.data.phone);
      setValue('address', response.data.data.data.address);

    } catch (err) {
      //
    }
  }, [isMountedRef]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit = async () => {
    try {
      await axiosInstance.post('/information/contact_us/update', getValues());
      enqueueSnackbar('Update success!');
    } catch (error) {
      console.error(error);
      if (isMountedRef.current) {
        setError('afterSubmit', error);
      }
    }
  };

  return (
    <Page title="Information: Contact Us">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Contact Us"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Information', href: PATH_DASHBOARD.information.root },
            { name: 'Contact Us' },
          ]}
        />

        <Card>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {!!errors.afterSubmit && (
              <Alert sx={{ mb: 2 }} severity="error">
                {errors.afterSubmit.message}
              </Alert>
            )}
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>

                    {/* <RHFTextField name="name" label="Video Name" /> */}

                    <RHFTextField name="email" label="Email Address" />
                    <RHFTextField name="phone" label="Phone Number" />

                    <div>
                      <RHFTextField name="address" label="Address" multiline rows={5} />
                    </div>
                    {/* <div> */}

                    {/* <RHFEditor name="content" /> */}
                    {/* </div> */}
                    <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                      Update
                    </LoadingButton>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </FormProvider>
        </Card>
      </Container>
    </Page>
  );
}
