import { paramCase } from 'change-case';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
} from '@mui/material';
// routes
import { useSnackbar } from 'notistack';
import axiosInstance from '../../utils/axios';
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';
// _mock_
import { _userList } from '../../_mock';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../components/table';
// sections
import { VideoTableRow, VideoTableToolbar } from '../../sections/@dashboard/video/list';
import useIsMountedRef from '../../hooks/useIsMountedRef';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = ['all'];


const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'category', label: 'Category', align: 'left' },
  { id: 'likes', label: 'Likes', align: 'center' },
  { id: 'views', label: 'Views', align: 'center' },
  { id: 'uniqueViews', label: 'Unique Views', align: 'center' },
  { id: 'active', label: 'Status', align: 'left' },
  { id: 'createdAt', label: 'Created On', align: 'left' },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function VideoList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);

  const [filterName, setFilterName] = useState('');


  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();




  const getVideos = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/video/list');
  
      setTableData(response.data.data.docs);
     
  
    } catch (err) {
      //
    }
  }, [isMountedRef]);

  useEffect(() => {
    getVideos();
  },[getVideos]);

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };



  const handleDeleteRow = async (id) => {
    try {
      await axiosInstance.get(`video/${id}/delete`);
      
      const deleteRow = tableData.filter((row) => row.id !== id);
      setSelected([]);
      setTableData(deleteRow);
      enqueueSnackbar("Video deleted", { variant: 'success' });
         
  
    } catch (err) {
      enqueueSnackbar("Failed to delete", { variant: 'error' });
    }
    
    
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = tableData.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setTableData(deleteRows);
  };

  const handleViewRow = (id) => {
    navigate(PATH_DASHBOARD.video.view(paramCase(id)));
  };

  const handleEditRow = (id) => {
    navigate(PATH_DASHBOARD.video.edit(paramCase(id)));
  }

  const handleStatusRow = async (id, active) => {
    try {
      await axiosInstance.post(`video/${id}/update-status`, { active });
      const actionRow = tableData.map((row) => {
        
        if (row.id === id) {
          row.active = active;
        }
        return row;
      });
      setTableData(actionRow);
      enqueueSnackbar("Video updated", { variant: 'success' });
         
  
    } catch (err) {
      enqueueSnackbar("Failed to update", { variant: 'error' });
    }
    
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName)

  return (
    <Page title="Video: List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Video List"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Video', href: PATH_DASHBOARD.video.root },
            { name: 'List' },
          ]}
          action={
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              component={RouterLink}
              to={PATH_DASHBOARD.video.create}
            >
              New Video
            </Button>
          }
          
        />

        <Card>
          

          <Divider />

          <VideoTableToolbar
            filterName={filterName}
            
            onFilterName={handleFilterName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  // onSelectAllRows={(checked) =>
                  //   onSelectAllRows(
                  //     checked,
                  //     tableData.map((row) => row.id)
                  //   )
                  // }
                />

                <TableBody>
                  {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <VideoTableRow
                      key={row.id}
                      row={row}
                      selected={selected.includes(row.id)}
                      onSelectRow={() => onSelectRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      onViewRow={() => handleViewRow(row.id)}
                      onEditRow={() => handleEditRow(row.id)}
                      onStatusRow={(active) => handleStatusRow(row.id, active)}
                    />
                  ))}

                  <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName}) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter((item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
  }


  return tableData;
}
