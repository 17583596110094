/* eslint-disable camelcase */
import { paramCase } from 'change-case';
import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Avatar, Checkbox, TableRow, TableCell, Typography, MenuItem } from '@mui/material';
// components
import { useNavigate } from 'react-router';
import { format } from 'date-fns';
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import { PATH_DASHBOARD } from '../../../../routes/paths';


// ----------------------------------------------------------------------

VideoTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onStatusRow: PropTypes.func,
};

export default function VideoTableRow({ row, selected, onViewRow, onEditRow, onSelectRow, onDeleteRow, onStatusRow }) {
  const theme = useTheme();

  const { id, name, category, views, likes, active, createdAt} = row;
  const navigate = useNavigate();

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected}>
      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}

      {/* <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={name} src={avatarUrl} sx={{ mr: 2 }} />
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>
      </TableCell> */}

      <TableCell align="left">
        {name}
      </TableCell>

      <TableCell align="left">
        {category?.name}
      </TableCell>

      <TableCell align="center">
         <Label 
         sx={{cursor: 'pointer'}}
         onClick={()=>{ navigate(PATH_DASHBOARD.video.like(paramCase(id)));}}
                        variant={'ghost'}
                        color={
                          'warning'
                        }
                      >
                        {`${likes} LIKES`}
                      </Label>
      </TableCell>

      <TableCell align="center">
      <Label sx={{cursor: 'pointer'}}
      onClick={()=>{ navigate(PATH_DASHBOARD.video.view(paramCase(id)));}}
                        variant={'ghost'}
                        color={
                          'warning'
                        }
                      >
                        {`${views} VIEWS`}
                      </Label>
      </TableCell>

      <TableCell align="center">
      <Label 
      // sx={{cursor: 'pointer'}}
      // onClick={()=>{ navigate(PATH_DASHBOARD.video.view(paramCase(id)));}}
                        variant={'ghost'}
                        color={
                          'warning'
                        }
                      >
                        {`${views} VIEWS`}
                      </Label>
      </TableCell>

      

      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={(!active  && 'error') || 'success'}
          sx={{ textTransform: 'capitalize' }}
        >
          {active ? 'Active' : 'Inactive'}
        </Label>
      </TableCell>
      <TableCell>
      {createdAt && format(new Date(createdAt), 'MMM dd, yyyy')}
      </TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
            <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Edit
              </MenuItem>
            {active && (<MenuItem
                onClick={() => {
                  onStatusRow(!active);
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:lock-outline'} />
                Deactivate
              </MenuItem>)}
              {!active && (<MenuItem
                onClick={() => {
                  onStatusRow(!active);
                  handleCloseMenu();
                }}
                sx={{ color: 'success.main' }}
              >
                <Iconify icon={'eva:lock-outline'} />
                Activate
              </MenuItem>)}
              <MenuItem
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
