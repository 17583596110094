// import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
// import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { Card, Chip, Grid, Stack, TextField, Typography, Autocomplete, InputAdornment, Alert, Divider } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import {
  FormProvider,
  RHFSwitch,
  RHFSelect,
  // RHFEditor,
  RHFTextField,
  // RHFRadioGroup,
  // RHFUploadMultiFile,
  // RHFUploadSingleFile,
} from '../../../components/hook-form';
import { LinearProgressWithLabel } from '../../../components/ProgressBar';
// import fileUpload from '../../../utils/fileUpload';
import axiosInstance from '../../../utils/axios';
import useIsMountedRef from '../../../hooks/useIsMountedRef';


// ----------------------------------------------------------------------





// const LabelStyle = styled(Typography)(({ theme }) => ({
//   ...theme.typography.subtitle2,
//   color: theme.palette.text.secondary,
//   marginBottom: theme.spacing(1),
// }));

// ----------------------------------------------------------------------



export default function SubscriptionViewForm() {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  // const [categoryList, setCategoryList] = useState([]);
  const isMountedRef = useIsMountedRef();
  const [progress, setProgress] = useState(0);

  const NewSubscriptionSchema = Yup.object().shape({
    title: Yup.string().required('Name is required'),
    description: Yup.string().max(500, "Max 500 Characters allowed").required('Long Description is required'),
    validity: Yup.string().required('Validity is required'),
    price: Yup.number().moreThan(0, 'Price should not be $0.00').required('Price is required'),
    hasOffer: Yup.bool(),
    offer_price: Yup.number().moreThan(-1, 'Offer Price cannot be negative').max(Yup.ref('price'), 'Offer price should be less than price'),
    type: Yup.string().required('Type is required'),
    trial: Yup.bool().required('Trial status is required'),
    trial_days: Yup.number().when("trial", {
      is: true,
      then: Yup.number().moreThan(0, "Enter valid trial days")
    }),
    unMaxTeamMember: Yup.bool(),
    maxTeamMember: Yup.number().when("type", {
      is: 'business_sub',
      then: Yup.number().when("unMaxTeamMember",{
        is: false,
        then: Yup.number().moreThan(0, "Enter valid max team members"),
      }),
    }),
    unMaxProject: Yup.bool(),
    maxProject: Yup.number().when("type", {
      is: 'business_sub',
      then: Yup.number().when("unMaxProject",{
        is: false,
        then: Yup.number().moreThan(0, "Enter valid max project allowed"),
      }),
    }),
    
    
    businessCollaboration: Yup.bool().required('Business Collaboration is required'),
    order: Yup.number().moreThan(0, 'Order should be more than 0'),
  });

  const defaultValues = useMemo(
    () => ({
      title: '',
      description: '',
      validity: 'monthly',
      price: 0,
      hasOffer: false,
      offer_price: 0,
      type: 'business_sub',
      trial: false,
      trial_days: 0,
      maxTeamMember: 0,
      maxProject: 0,
      unMaxTeamMember: false,
      unMaxProject: false,
      active: true,
      businessCollaboration: false,
      order: 1,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // const getCategories = useCallback(async () => {
  //   try {
  //     const response = await axiosInstance.get('/category/list');

  //     setCategoryList(response.data.data.docs);


  //   } catch (err) {
  //     //
  //   }
  // }, [isMountedRef]);

  // useEffect(() => {
  //   getCategories();
  // }, [getCategories]);

  const methods = useForm({
    resolver: yupResolver(NewSubscriptionSchema),
    defaultValues,
  });

  const {
    // reset,
    watch,
    // control,
    setError,
    // setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();

  // const handleDrop = useCallback(
  //   (acceptedFiles) => {
  //     const file = acceptedFiles[0];

  //     if (file) {
  //       setValue(
  //         'video_url',
  //         Object.assign(file, {
  //           preview: URL.createObjectURL(file),
  //         })
  //       );
  //     }
  //   },
  //   [setValue]
  // );

  const onSubmit = async () => {
    let data = {};
    let body = {};
    try {
      // setProgress(0)
      data = getValues();
      body = {
        active: data.active,
        businessCollaboration: data.businessCollaboration,
        description: data.description,
        maxProject: data.unMaxProject ? "-1" : data.maxProject,
        maxTeamMember: data.unMaxTeamMember ? "-1" : data.maxTeamMember,
        hasOffer: data.hasOffer,
        offer_price: data.hasOffer?data.offer_price:0,
        order: data.order,
        price: data.price,
        title: data.title,
        trial: data.trial,
        trial_days: data.trial_days,
        type: data.type,
        validity: data.validity,
      };
      
      await axiosInstance.post(`/subscription-plan/create`, body)
      // // await new Promise((resolve) => setTimeout(resolve, 500));
      // // reset();
      enqueueSnackbar('Create success!');
      navigate(PATH_DASHBOARD.subscription.list);
    } catch (error) {
      console.error(error);
      // if (isMountedRef.current) {
      //   setError('afterSubmit', error);
      // }
    }
  };


  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur()
    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }


  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {!!errors.afterSubmit && <Alert sx={{ mb: 2 }} severity="error">{errors.afterSubmit.message}</Alert>}
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <RHFTextField name="title" label="Subscription Name" />
              <div>
                <RHFTextField name="description" label="Long Description" multiline rows={5} />
              </div>

              <div>
                <RHFSelect name="validity" label="Period">
                  <option key='monthly' value='monthly'>Monthly</option>
                  <option key='quarterly' value='quarterly'>Quarterly</option>
                  <option key='half_yearly' value='half_yearly'>Half Yearly</option>
                  <option key='yearly' value='yearly'>Yearly</option>
                </RHFSelect>
              </div>

              <div>
                <RHFTextField name="price" label="Price" type="number" onWheel={numberInputOnWheelPreventChange} />
              </div>

              <Grid container spacing={1}>
                <Grid item xs={3} marginY={1}>
                    <RHFSwitch name="hasOffer" label="Has Offer?" />
                  </Grid>

                {values.hasOffer && <Grid item xs={9}>
                  <RHFTextField name="offer_price" label="Offer Price" type="number" onWheel={numberInputOnWheelPreventChange}/>
                </Grid>}
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={3} marginY={1}>
                  <RHFSwitch name="trial" label="Free Trial" />
                </Grid>
                <Grid item xs={9}>
                  {values.trial === true && <RHFTextField name="trial_days" label="Trail Period (In Days)" type="number" onWheel={numberInputOnWheelPreventChange}/>}
                </Grid>
              </Grid>


              {values.type === 'business_sub' && <>

                <Grid container spacing={1}>
                  <Grid item xs={5} marginY={1}>
                    <RHFSwitch name="unMaxTeamMember" label="Unlimited Team Member" />
                  </Grid>

                  {(values.unMaxTeamMember === false) && <Grid item xs={7}>
                    <RHFTextField name="maxTeamMember" label="Max Team Members Allowed" type="number" onWheel={numberInputOnWheelPreventChange}/>
                  </Grid>
                  }
                </Grid>



              </>}

              {values.type === 'business_sub' && <>

                <Grid container spacing={1}>
                  <Grid item xs={5} marginY={1}>

                    <RHFSwitch name="unMaxProject" label="Unlimited Team Member" />
                  </Grid>
                  {(values.unMaxProject === false) && <Grid item xs={7}>
                    <RHFTextField name="maxProject" label="Max Project Allowed" type="number" onWheel={numberInputOnWheelPreventChange}/>
                  </Grid>
                  }
                </Grid>



              </>}

              {values.type === 'business_sub' && <Grid container spacing={1}>
                <Grid item xs={6} marginY={1}>
                  <RHFSwitch name="businessCollaboration" label="Business Collaboration" />
                </Grid>
              </Grid>}


              <div>
                <RHFTextField name="order" label="Order" type="number" onWheel={numberInputOnWheelPreventChange} />
              </div>

              <Typography variant="caption" color="primary" sx={{
                fontStyle: "italic"
              }}>
                * Order in which subscription will be shown in the frontend. Least value will be shown first
              </Typography>

            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3} mt={2}>
                <RHFSwitch name="active" label="Active" />

                <RHFSelect name="type" label="Type">
                  <option key='business_sub' value='business_sub'>Business</option>
                  <option key='electrician_sub' value='electrician_sub'>Electrician</option>
                </RHFSelect>
              </Stack>
            </Card>



            {isSubmitting && progress > 0 && <LinearProgressWithLabel value={progress} />}
            {<LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
              {'Create'}
            </LoadingButton>}
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
