import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg width="40" height="40" viewBox="0 0 90 78" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M66.3964 21.3853H64.9843C64.8593 17.318 63.5013 13.3847 61.0906 10.1064C58.6798 6.82817 55.3299 4.35974 51.4847 3.02814L49.3101 14.1695L49.0277 2.33621L49.1265 1.41836C49.0574 1.00446 48.8372 0.630905 48.5085 0.370041C48.1798 0.109177 47.7661 -0.0204414 47.3473 0.00626625H42.5885C42.1676 -0.0304841 41.7487 0.0953979 41.4177 0.358039C41.0868 0.620681 40.8691 1.00012 40.8093 1.41836L40.9082 2.33621L40.6116 14.1695L38.4229 3.02814C34.5703 4.35164 31.2121 6.81718 28.7954 10.0966C26.3788 13.3759 25.0183 17.3135 24.895 21.3853H23.4829C22.6296 21.4798 21.841 21.8857 21.2683 22.5254C20.6955 23.165 20.3789 23.9934 20.3789 24.852C20.3789 25.7106 20.6955 26.539 21.2683 27.1786C21.841 27.8183 22.6296 28.2242 23.4829 28.3187H66.354C67.2074 28.2242 67.996 27.8183 68.5687 27.1786C69.1414 26.539 69.4581 25.7106 69.4581 24.852C69.4581 23.9934 69.1414 23.165 68.5687 22.5254C67.996 21.8857 67.2074 21.4798 66.354 21.3853H66.3964ZM27.4509 22.2891C28.0722 10.3287 35.3304 6.65721 35.3304 6.65721L27.4509 22.2891Z" fill="#01B433"/>
<path d="M27.1679 32.1458H24.6262C24.665 32.5235 24.6167 32.9051 24.485 33.2613C24.1038 34.1509 23.8213 35.21 23.8213 40.0817C23.8972 40.6561 24.0985 41.2068 24.4108 41.6949C24.7232 42.1829 25.139 42.5963 25.6288 42.9059C25.9447 43.1469 26.2335 43.4215 26.4902 43.7249C29.4838 52.5504 36.3748 61.4467 44.9744 61.4467C50.7216 61.4467 59.0106 56.815 63.4587 43.7531C63.7313 43.3054 64.0693 42.9008 64.4613 42.5529C65.4721 41.7663 66.1358 40.6161 66.3112 39.3474C66.3112 34.8711 65.944 33.8685 65.4639 33.0212C65.365 32.8518 65.3227 32.7812 65.3227 32.2022H62.7527C62.6927 32.9255 62.8553 33.6498 63.2187 34.278C63.4023 34.6028 63.7411 35.1817 63.7411 39.3474C63.7411 39.6298 62.9786 40.3923 62.6255 40.7595C61.9377 41.3535 61.3883 42.091 61.0158 42.92C58.3187 50.8701 52.3032 58.8908 44.9603 58.8908C37.6175 58.8908 31.6019 50.8842 28.8625 42.8494C28.5084 42.0684 27.9527 41.3958 27.2527 40.9007C26.9095 40.6397 26.6011 40.336 26.3349 39.997C26.3349 35.3371 26.6173 34.6734 26.7867 34.2639C27.0799 33.598 27.2105 32.872 27.1679 32.1458Z" fill="#01B433"/>
<path d="M44.9331 70.4284C38.6069 70.4284 32.8314 66.1921 28.468 60.1201H22.9609V77.2629H66.99V60.1201H61.4828C57.1053 66.2062 51.3722 70.4284 44.9331 70.4284Z" fill="#01B433"/>
<path d="M0.197266 77.2629H17.3401V60.1201C12.6096 60.1201 0.197266 72.5324 0.197266 77.2629Z" fill="#01B433"/>
<path d="M72.5537 60.1201V77.2629H89.6965C89.6965 72.5324 77.2983 60.1201 72.5537 60.1201Z" fill="#01B433"/>
</svg>

    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
