import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
// @mui
import { Alert, Button, Card, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// routes
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// components
import { FormProvider, RHFEditor, RHFTextField } from '../../components/hook-form';
// components
import Page from '../../components/Page';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import useSettings from '../../hooks/useSettings';
import { PATH_DASHBOARD } from '../../routes/paths';
import axiosInstance from '../../utils/axios';



const DataSchema = Yup.object().shape({
  // email: Yup.string().required('Email is required').email(),
  // phone: Yup.string().required('Phone number is required'),
  // address: Yup.string().required('Address is required'),
  faq: Yup.array(Yup.object().shape({
    question: Yup.string().required('Question is required'),
    answer: Yup.string().required('Answer is required')
  })).min(1).required()
});


const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

export default function FAQ() {
  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const [data, setData] = useState('');

  const [faq, setFAQ] = useState([]);

  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () => ({
      faq,
    }),
    [faq]
  );

  const methods = useForm({
    resolver: yupResolver(DataSchema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    getValues,
    setError,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();

  const getData = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/information/faq');

      setValue('faq', response.data.data.data.faq);
      setFAQ(response.data.data.data.faq)

    } catch (err) {
      //
    }
  }, [isMountedRef]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit = async () => {
    try {
      await axiosInstance.post('/information/faq/update', getValues());
      enqueueSnackbar('Update success!');
    } catch (error) {
      console.error(error);
      if (isMountedRef.current) {
        setError('afterSubmit', error);
      }
    }
  };

  return (
    <Page title="Information: FAQ">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="FAQ"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Information', href: PATH_DASHBOARD.information.root },
            { name: 'FAQ' },
          ]}
        />

        <Card>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {!!errors.afterSubmit && (
              <Alert sx={{ mb: 2 }} severity="error">
                {errors.afterSubmit.message}
              </Alert>
            )}
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={5}>
                    {faq.map((item, i)=>{
                      return <Stack key={i} spacing={3}>
                        <Typography variant="body1"><strong>FAQ {i+1}</strong></Typography>
                        <RHFTextField name={`faq.${i}.question`} label="Question" />
                        <RHFTextField name={`faq.${i}.answer`}label="Answer" multiline rows={3} />
                        <Button variant='outlined' color='error' onClick={()=>{
                          const _faq = faq;
                          _faq.splice(i,1);
                          setValue('faq',_faq);
                        }}>Remove</Button>
                        <Divider />
                      </Stack>
                    })}

                    <Button variant='outlined' onClick={()=>{
                      const _faq = faq;
                      _faq.push({question:'', answer:''});
                      setValue('faq',_faq);
                    }}>Add FAQ</Button>
                    <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                      Save
                    </LoadingButton>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </FormProvider>
        </Card>
      </Container>
    </Page>
  );
}
