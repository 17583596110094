import { paramCase } from 'change-case';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  // Tab,
  // Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Tabs,
  Tab,
} from '@mui/material';
// routes
import { useSnackbar } from 'notistack';
import axiosInstance from '../../utils/axios';
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
// import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';
// _mock_
// import { _userList } from '../../_mock';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../components/table';
// sections
import { SubscriptionTableRow, SubscriptionTableToolbar } from '../../sections/@dashboard/subscription/list';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import useTabs from '../../hooks/useTabs';
// ----------------------------------------------------------------------

// const STATUS_OPTIONS = ['all'];


const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'type', label: 'Type', align: 'left' },
  { id: 'period', label: 'Period', align: 'center' },
  { id: 'price', label: 'Price', align: 'center' },
  { id: 'offerPrice', label: 'Offer Price', align: 'center' },
  { id: 'trial', label: 'Trial', align: 'left' },
  { id: 'status', label: 'Mode', align: 'left' },
  { id: 'businessCollaboration', label: 'Collaboration' , align : 'left'},
  { id: 'order', label: 'Order', align: 'left' },
  { id: 'active', label: 'Status', align: 'left' },
  { id: 'createdAt', label: 'Created On', align: 'left' },
  { id: '' },
];

const STATUS_OPTIONS = ['all', 'draft', 'published'];

// ----------------------------------------------------------------------

export default function SubscriptionList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);

  const [filterName, setFilterName] = useState('');


  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');




  const getSubscriptions = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/subscription-plan/list?active=true');
  
      setTableData(response.data.data.docs);
     
  
    } catch (err) {
      //
    }
  }, [isMountedRef]);

  useEffect(() => {
    getSubscriptions();
  },[getSubscriptions]);

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };



  const handleDeleteRow = async (id) => {
    try {
      await axiosInstance.get(`subscription-plan/${id}/delete`);
      
      const deleteRow = tableData.filter((row) => row._id !== id);
      setSelected([]);
      setTableData(deleteRow);
      enqueueSnackbar("Subscription deleted", { variant: 'success' });
         
  
    } catch (err) {
      enqueueSnackbar("Failed to delete", { variant: 'error' });
    }
    
    
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = tableData.filter((row) => !selected.includes(row._id));
    setSelected([]);
    setTableData(deleteRows);
  };

  const handleViewRow = (id) => {
    navigate(PATH_DASHBOARD.subscription.view(paramCase(id)));
  };

  const handleEditRow = (id) => {
    navigate(PATH_DASHBOARD.subscription.edit(paramCase(id)));
  }

  const handleStatusRow = async (id) => {
    try {
      await axiosInstance.get(`subscription-plan/${id}/publish`);
      const actionRow = tableData.map((row) => {
        
        if (row._id === id) {
          row.status = 'published';
        }
        return row;
      });
      setTableData(actionRow);
      enqueueSnackbar("Subscription Published", { variant: 'success' });
         
  
    } catch (err) {
      enqueueSnackbar("Failed to published", { variant: 'error' });
    }
    
  };

  const handleActiveRow = async (id, active) => {
    try {
      await axiosInstance.post(`subscription-plan/${id}/update-status`, { active });
      const actionRow = tableData.map((row) => {
        
        if (row._id === id) {
          row.active = active;
        }
        return row;
      });
      setTableData(actionRow);
      enqueueSnackbar("Subscription updated", { variant: 'success' });
      getSubscriptions();
  
    } catch (err) {
      enqueueSnackbar("Failed to update", { variant: 'error' });
    }
    
  };


  const handleRecommended = async(id,recommended)=>{
    try{
      await axiosInstance.post(`subscription-plan/${id}/update-recommended`, { "recommended": recommended });
      const actionRow = tableData.map((row) => {
        
        if (row._id === id) {
          row.recommended = recommended;
        }
        return row;
      });
      setTableData(actionRow);
      enqueueSnackbar("Subscription updated", { variant: 'success' });
      getSubscriptions();
    }catch (err){
      enqueueSnackbar("Failed to update", { variant: 'error' });
    }
  }

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName)||
    (!dataFiltered.length && !!filterStatus);

  return (
    <Page title="Subscription: Active List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Active Subscription List"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Active Subscription', href: PATH_DASHBOARD.subscription.inactiveList },
            // { name: 'Active List' },
          ]}
          action={
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              component={RouterLink}
              to={PATH_DASHBOARD.subscription.create}
            >
              New Subscription
            </Button>
          }
          
        />

        <Card>

        <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onChangeFilterStatus}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab} value={tab} />
            ))}
          </Tabs>
          

          <Divider />

          <SubscriptionTableToolbar
            filterName={filterName}
            
            onFilterName={handleFilterName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row._id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  // onSelectAllRows={(checked) =>
                  //   onSelectAllRows(
                  //     checked,
                  //     tableData.map((row) => row.id)
                  //   )
                  // }
                />

                <TableBody>
                  {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <SubscriptionTableRow
                      key={row._id}
                      row={row}
                      selected={selected.includes(row._id)}
                      onSelectRow={() => onSelectRow(row._id)}
                      onDeleteRow={() => handleDeleteRow(row._id)}
                      onViewRow={() => handleViewRow(row._id)}
                      onEditRow={() => handleEditRow(row._id)}
                      onStatusRow={() => handleStatusRow(row._id)}
                      onRecommendedRow= {(recommended)=> handleRecommended(row._id, recommended)}
                      onActiveRow={(active) => handleActiveRow(row._id, active)}
                      onOrderUpdate={()=> getSubscriptions()}
                    />
                  ))}

                  <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName,filterStatus}) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter((item) => item.title.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
  }

  if (filterStatus === 'draft') {
    tableData = tableData.filter((item) => item.status === 'draft');
  }
  
  if (filterStatus === 'published') {
    tableData = tableData.filter((item) => item.status === 'published');
  }


  return tableData;
}
